import * as React from "react";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import { useParams, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import ForgotPasswordLink from "./ForgotPasswordLink";
import { addFlashMessage } from "../../redux/actions/flashMessages";

import { setPassword as SETPASSWORD } from "../../graphql/users/Mutations";

function SetPassword() {
  let { resetId } = useParams();
  let history = useHistory();
  const [setPassword, { loading, error }] = useMutation(SETPASSWORD);
  const dispatch = useDispatch();

  return (
    <div className="container mx-auto h-full flex justify-center items-center m-8">
      <div className="w-full max-w-xs content-center">
        {loading && <h2>Loading</h2>}
        {error && <h2>error</h2>}
        <div className="mb-4">
          <h1 className="content-center">Password Reset</h1>
        </div>

        <Formik
          initialValues={{
            password: "",
          }}
          onSubmit={(values) => {
            setPassword({
              variables: {
                token: resetId,
                password: values.password,
              },
            }).then((response) => {
              const { flashMessage } = response.data.setPassword;
              if (flashMessage) {
                if (flashMessage.type === "success") {
                  history.push("/login");
                } else {
                  history.push("/reset_password");
                }
                dispatch(addFlashMessage(flashMessage));
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form
              className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
              onSubmit={handleSubmit}
            >
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder="********"
                />
                {touched.password && errors && errors.password && (
                  <p className="text-red-500 text-xs italic">
                    {errors.password}
                  </p>
                )}
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Reset Password
                </button>
                <ForgotPasswordLink />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default connect()(SetPassword);
