import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { SubmitButton } from "../Shared/styles";
import { UPDATE_OBJECTIVE } from "../../graphql/objectives/Mutations";
import { HeadingRow } from "../Shared/HeadingRow"

const validationSchema = yup.object().shape({
  objective: yup
    .string()
    .min(5, "Objective should be at least 5 charactors")
    .required("Required")
});

const ObjectiveForm = ({ id, action, nextStep }) => {
  const [updateObjective] = useMutation(UPDATE_OBJECTIVE);
  const dispatch = useDispatch();

  const {handleSubmit, errors, handleChange, values} = useFormik({
    initialValues: {
      id: id || '',
      action: action,
      objective: "",
    },
    validationSchema,
    onSubmit: (values) => {
      updateObjective({
        variables: {
          id: values.id,
          action: values.action,
          objective: values.objective,
        },
      }).then((response) => {
        const { objectiveReturn } = response.data.updateObjective;
        const { flashMessage, objective } = objectiveReturn;

        if (flashMessage) {
          dispatch(addFlashMessage(flashMessage));
        }
        nextStep(objective.id);
      });
    },
  });

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="mb-4 text-center">Step 1</h3>
        <div className="lg:mx-48">
          <h4 className="mb-4 mt-4 lg:mx-40 border-t-4 border-gray-200 justify-self-auto text-center ">An objective should be high level value, that we'll break down in the
            next steps. (i.e. Make our Customer love our support team.)</h4>
          <HeadingRow
            heading={"Objective:"}
            content={
              <div>
                <input
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  id="objective"
                  name="objective"
                  type="text"
                  onChange={handleChange}
                  value={values.objective}
                  initialvalue=""
                  placeholder="New Objective"
                />
              <p className="text-red-500 text-xs italic">{errors.objective && errors.objective}</p>
              </div>
              }
            />
          <div className="text-center my-8"><SubmitButton type="submit" >Next Step</SubmitButton></div>
        </div>
      </form>
    </div>
  );
};

export default connect()(ObjectiveForm);
