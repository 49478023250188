import React from "react";
import { Formik, Form, Field } from "formik";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { WEEK_ITEM_PROGRESS_UPDATE } from "../../graphql/weeks/Mutations";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";

const WeekItemProgressForm = ({
  weekId,
  itemId,
  progress,
  setEditingFalse,
  refetch
}) => {
  const [week_item_progress_update] = useMutation(WEEK_ITEM_PROGRESS_UPDATE);
  const dispatch = useDispatch();
  return(
    <Formik
      initialValues={{
        weekId: String(weekId),
        itemId: String(itemId),
        progress: String(progress.toFixed(1)) || "0.0"
      }}
      onSubmit={values => {
        week_item_progress_update({
          variables: {
            weekId: values.weekId,
            itemId: values.itemId,
            progress: values.progress
          },
        }).then((response) => {
          const { flashMessage } = response.data.weekItemProgressUpdate;
          if (flashMessage) {
            dispatch(addFlashMessage(flashMessage));
          }
          setEditingFalse()
          refetch()
        });
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit} style={{display: "inline"}}>
          <Field as="select" name="progress">
            <option value="0.0">0%</option>
            <option value="0.1">10%</option>
            <option value="0.2">20%</option>
            <option value="0.3">30%</option>
            <option value="0.4">40%</option>
            <option value="0.5">50%</option>
            <option value="0.6">60%</option>
            <option value="0.7">70%</option>
            <option value="0.8">80%</option>
            <option value="0.9">90%</option>
            <option value="1.0">100%</option>
          </Field>
            <button
              type="submit" >
              <CheckIcon
                width="25px"
                height="25px"
                className="inline-block"
              />
            </button>
            <button
              onClick={() => setEditingFalse()} >
              <CautionIcon
                width="25px"
                height="25px"
                className="inline-block"
              />
            </button>
        </Form>
      )}
    </Formik>
  )
};

export default connect()(WeekItemProgressForm);