import React, { useState } from 'react'

import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { progressCircle } from "../Shared/ProgressColor";
import { circleWithPercent } from "../Shared/CircleWithPercent";
import TodayTodoProgressForm from "./TodayTodoProgressForm";
import TodayTodoComplete from "./TodayTodoComplete";
import TodayTodoDelete from "./TodayTodoDelete";
import TodoEdit from "./TodoEdit"

export const TodoItem = ({todo, todayId, refetch}) => {
  const [isEditing, setEditing] = useState(false);
  const [isEditingToDo, setEditingToDo] = useState(false);
  const circle = circleWithPercent((todo.progress * 100), progressCircle(todo.progress.toFixed(2)))
  const editing = isEditing ? (
    <TodayTodoProgressForm
      todayId={todayId}
      todoId={todo.id}
      type={"todo"}
      progress={todo.progress}
      setEditingFalse={() => setEditing(false)}
      refetch={refetch}
      />
    ) : (
      <div className="inline-flex">{circle}</div>
    );
  const editingTodo = isEditingToDo ? (
    <TodoEdit
      todayId={todayId}
      todoId={todo.id}
      todo={todo.todo}
      setEditingFalse={() => setEditingToDo(false)}
      refetch={refetch}
      />
    ) : todo.todo
  return (
    <li className="inline-flex w-full">
      {editing}
      {setEditing && (
        <div className="inline-flex mx-1">
          <button
            className="inline-flex"
            onClick={() => setEditing(!isEditing)}>
            {isEditing === false && (
              <div className="inline-flex mx-1">
                <EditIcon
                  width="25px"
                  height="25px"
                  className="inline-block"
                />
              </div>)}
          </button>
          {isEditing === false && (
            <TodayTodoComplete
              todayId={todayId}
              todoId={todo.id}
              type={"todo"}
              refetch={refetch}
            />)}
        </div>
      )}
      {' '}{editingTodo}{' '}
      {setEditingToDo &&
        (<button className="inline-flex" onClick={() => setEditingToDo(!isEditingToDo)}>
          {isEditingToDo === false && (
              <EditIcon
                width="25px"
                height="25px"
                className="inline-block"
              />
          )}
        </button>
        )}
      {!isEditingToDo && (
        <div className="inline-flex">
          <TodayTodoDelete
            todayId={todayId}
            todoId={todo.id}
            type={"todo"}
            refetch={refetch}
            />
        </div>
      )}

    </li>
  )
}