import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { myObjectives } from "../../graphql/users/Queries";
import { Objective } from "./Objective";


function MeView() {
  const { loading, error, data, refetch } = useQuery(myObjectives, {
    fetchPolicy: "no-cache"
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;
  const length = data.objectives.length

  return (
    <div className="grid grid-cols-12 bg-gray-400 min-h-screen h-auto">
      <div className="sm:col-start-2 col-span-12 sm:col-span-10">
        <div className="grid grid-cols-8">
          {length > 0 ?
            data.objectives.map((objective) => (
              <Objective key={objective.id} objective={objective} length={length} refetch={refetch} />
            )) : (
              <div className="m-2 col-span-8 lg:col-span-8">
                <div className="rounded m-2 col-span-8">
                  <div className="bg-white rounded overflow-hidden sm:rounded-lg ">
                    <div className="bg-white rounded px-8 pt-6 pb-8 mb-4 text-center">
                      <h1 className="mb-4">Welcome to Lidger’s Objectives and Key Results Tools (OKRs)</h1>
                      <p className="border-t-4 border-gray-200 lg:mx-40">OKRs are goal-setting tool used by teams and individuals to set challenging, ambitious goals with measurable results.</p>
                      <p className="lg:mx-40 m-4">OKRs are mainly used by corporations to set quarterly or annual ambitious goals.</p>
                      <p className="lg:mx-40 m-4 mb-10">We have adopted OKR’s for ambitious individuals. That want to achieve greater success in their lives.</p>
                      <button
                        type="button"
                        className="text-xs bg-green-500 hover:bg-green-700 text-white font-bold py-2 my-2 px-4 rounded animate-bounce"
                      >
                        <Link className="font-bold mx-10" to={"new_objectives"}>
                          <p className="inline-block text-center ">Start Building Your Objective</p>
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default MeView;