import * as React from "react";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import { connect, useDispatch } from "react-redux";

import { addFlashMessage } from "../../redux/actions/flashMessages";

import { requestPasswordReset as PASSWORD_RESET } from "../../graphql/users/Mutations";

function RequestPasswordReset() {
  const [passwordReset, { loading, error }] = useMutation(PASSWORD_RESET);
  const dispatch = useDispatch();

  return (
    <div className="container mx-auto h-full flex justify-center items-center m-8">
      <div className="w-full max-w-xs content-center">
        {loading && <h2>Loading</h2>}
        {error && <h2>error</h2>}
        <div className="mb-4">
          <h1 className="content-center">Password Reset</h1>
        </div>

        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={(values) => {
            passwordReset({
              variables: {
                email: values.email,
              },
            }).then((response) => {
              const { flashMessage } = response.data.requestPasswordReset;
              if (flashMessage) {
                dispatch(addFlashMessage(flashMessage));
              }
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form
              className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
              onSubmit={handleSubmit}
            >
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder="jackie.king@gmail.com"
                />
                {touched.firstName && errors && errors.firstName && (
                  <p className="text-red-500 text-xs italic">
                    {errors.firstName}
                  </p>
                )}
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Reset Password
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default connect()(RequestPasswordReset);
