import React from 'react'

export const circleWithPercent = (percent, color) => {
  const stroke = `stroke-current ${color}`
  const text = percent.toFixed(0) > 5 ? `${percent.toFixed(0)}%` : null
  return(
    <svg className={stroke} style={{width: "25px", height: "25px", display: "inline"}}>
      <circle cx="50%" cy="50%" r="11"strokeWidth="2" fill="white" />
      <text x="53%" y="60%" textAnchor="middle" fill="white" fontSize=".50rem">{text}</text>
    </svg>
  )
}
