import React from "react";
import { Formik, Form, Field } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { WEEK_COMPLETE } from "../../graphql/weeks/Mutations";
import { GET_WEEK } from "../../graphql/weeks/Queries";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { SubmitButton } from "../Shared/styles";
import { ItemsSection } from "./ItemsSection"
import { HeadingRow } from "../Shared/HeadingRow";

const CompleteWeekForm = () => {
  const { weekId } = useParams();
  const { loading, data, refetch} = useQuery(GET_WEEK, {
    variables: {id: weekId}
  });
  const [weekComplete] = useMutation(WEEK_COMPLETE);
  const dispatch = useDispatch();
  const history = useHistory();

  if (loading) {
    return null
  }

  const { items } = data.week

  return(
    <div className="grid grid-cols-12 bg-gray-400 min-h-screen h-auto">
      <div className="sm:col-start-2 col-span-12 sm:col-span-10">
        <div className="grid grid-cols-8">
          <div className="bg-white rounded m-2 col-span-8 px-8 pt-6 pb-8 mb-4">
            <div>
              <h1 className="mb-4 text-center">Wrap up your Week!</h1>
              <h4 className="mb-4 border-t-4 border-gray-200 justify-self-auto text-center lg:mx-40">Let's reflect on your week.</h4>
              <div className="grid grid-cols-10">
                <div className="invisible md:visible order-2 m-2 col-span-10 lg:col-span-4">
                  <h4 className="mb-4 border-t-4 border-gray-200 justify-self-auto text-center lg:mx-20">Your Items</h4>
                  <ItemsSection weekId={weekId} items={items} refetch={refetch} />
                </div>
                <div className="order-1 m-2 col-span-10 lg:col-span-6">
                  <Formik
                    initialValues={
                      {
                        effective: '',
                        improvement: '',
                        planExecution: ''
                      }
                    }
                    onSubmit={values => {
                      weekComplete({
                        variables: {
                          weekId: weekId,
                          effective: values.effective,
                          improvement: values.improvement,
                          planExecution: values.planExecution
                        },
                      }).then((response) => {
                        const { flashMessage } = response.data.weekComplete;
                        if (flashMessage) {
                          dispatch(addFlashMessage(flashMessage));
                        }
                        history.push(`/week/${weekId}`);
                      });
                    }}
                  >
                  {({ values }) => (
                    <div>
                      <Form>
                        <HeadingRow
                          heading={"What did you do well this week?"}
                          content={
                            <Field
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                              name="effective"
                              type="text"
                              placeholder="What did you do well."
                              />}
                        />
                        <HeadingRow
                          heading={"What's the something you could have done better?"}
                          content={
                            <Field
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                              name="improvement"
                              type="text"
                              placeholder="What could you have done better?"
                              />
                            }
                        />
                        <HeadingRow
                          heading={"How well did you execute your weekly plan?"}
                          content={
                            <Field
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                              name="planExecution"
                              type="text"
                              placeholder="How well did you follow the plan?"
                              />
                          }
                        />
                      <div className="text-center my-8"><SubmitButton type="submit">Complete your week</SubmitButton></div>
                    </Form>
                    </div>
                  )}
                  </Formik>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
};

export default connect()(CompleteWeekForm);