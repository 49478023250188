import gql from "graphql-tag";

export const GET_TODAY = gql`
  query today($id: ID!) {
    today(id: $id) {
      id
      todos
      notes
    }
  }
`;
