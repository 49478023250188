import React, { useState } from 'react'
import { TodoItem } from "./TodoItem";
import TodoAdd from "./TodoAdd"

export const TodosSection = ({todayId, todos, refetch}) => {
  const [isTodoAdding, setTodoAdding] = useState(false);
    const todoAdding = isTodoAdding ? (
      <TodoAdd
        todayId={todayId}
        type={"todo"}
        setEditingFalse={() => setTodoAdding(false)}
        refetch={refetch}
        />
    ) : (
      <button
        className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 my-2 px-4 rounded"
        onClick={() => setTodoAdding(!isTodoAdding)}
        >
        Add To-Do (8 Max)
      </button>
    );

  return (
    <ul>
      {todos.map(t => <TodoItem key={`${todayId}-${t.id}`} todo={t} todayId={todayId} refetch={refetch} />)}
      {todos.length < 8 && todoAdding }
    </ul>
  )
}