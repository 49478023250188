import gql from "graphql-tag";
import { FLASH_MESSAGE_FRAGMENT } from "../gqlFragments";

export const WEEK_CREATE_ITEMS = gql`
  mutation weekCreate($objectiveId: String!, $items: [JSON!], $commitment: String!) {
    weekCreate(input: { week: { objectiveId: $objectiveId, items: $items, commitment: $commitment } }) {
      weekReturn {
        flashMessage {
          ...FlashMessageFragment
        }
        week {
          id
        }
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const WEEK_COMPLETE = gql`
  mutation weekComplete($weekId: String!, $effective: String!, $improvement: String!, $planExecution: String!) {
    weekComplete(
      input: {
        week: { weekId: $weekId, effective: $effective, improvement: $improvement, planExecution: $planExecution }
      }
    ) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const WEEK_ITEM_PROGRESS_UPDATE = gql`
  mutation WeekItemProgressUpdate($weekId: String!, $itemId: String!, $progress: String!) {
    weekItemProgressUpdate(input: { weekId: $weekId, itemId: $itemId, progress: $progress }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const WEEK_ITEM_ADD = gql`
  mutation WeekItemAdd($weekId: String!, $item: JSON!) {
    weekItemAdd(input: { weekId: $weekId, item: $item }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const WEEK_ITEM_DELETE = gql`
  mutation WeekItemDelete($weekId: String!, $itemId: String!) {
    weekItemDelete(input: { weekId: $weekId, itemId: $itemId }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const WEEK_ITEM_UPDATE = gql`
  mutation WeekItemUpdate($weekId: String!, $itemId: String!, $item: String!) {
    weekItemUpdate(input: { weekId: $weekId, itemId: $itemId, item: $item }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const WEEK_NOTES_UPDATE = gql`
  mutation WeekNotesUpdate($weekId: String!, $note: String!, $noteEdit: Boolean!) {
    weekNotesUpdate(input: { weekId: $weekId, note: $note, noteEdit: $noteEdit }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;
