import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash'
import { Link } from "react-router-dom";
import { GratefulItem, AchievementItem, LessonItem, MemoryItem } from "./index"
import { HeadingRow } from "../Shared/HeadingRow"
import { BasicRow } from "../Shared/BasicRow"
import { progressColor, progressCircle } from "../Shared/ProgressColor";
import { circleWithPercent } from "../Shared/CircleWithPercent"
import { TodosSection } from "./TodosSection";
import { NotesSection } from "./NotesSection"

export const TodayItem = ({today, weekId, refetch}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const link = `/today/${weekId}/evening/${today.id}`
  const { todos, gratefuls, createdAt, achievements, lessons, memories, progress, notes} = today
  const classNameProgress = `overflow-hidden h-1 mb-4 text-xs flex rounded ` + progressColor(progress.toFixed(2));
  const circle = circleWithPercent((today.progress * 100), progressCircle(today.progress.toFixed(2)))

  return (
    <div className="py-5">
      <HeadingRow
        heading={<h2>{circle} {moment(createdAt).utcOffset(timezone).format("dddd")}</h2>}
        content={isEmpty(achievements) &&
            <Link
              className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              to={link}>Wrap up your day
            </Link>
            }
          />
      <dl>
        <BasicRow
          heading={"Daily To-dos:"}
          content={
            <div>
              <div className="relative pt-1">
                <div className={classNameProgress}></div>
              </div>
              <TodosSection todayId={today.id} todos={todos} refetch={refetch} />
            </div>

          } />
        <BasicRow
          heading={"Notes:"}
          content={
            <NotesSection todayId={today.id} notes={notes} refetch={refetch} />
          }
          />
         {!isEmpty(gratefuls) && (
            <BasicRow
              heading={"Gratefuls:"}
              content={<ul>{gratefuls.map(g => <GratefulItem key={`${today.id}-${g.id}`} grateful={g}/>)}</ul>} />
          )}
        <div>
          {!isEmpty(achievements) && (
            <BasicRow
              heading={"Achievements:"}
              content={<ul>{achievements.map(a => <AchievementItem key={`${today.id}-${a.id}`} achievement={a}/>)}</ul>} />
          )}
          {!isEmpty(lessons) && (
            <BasicRow
            heading={"Lessons:"}
            content={<ul>{lessons.map(l => <LessonItem key={`${today.id}-${l.id}`} lesson={l}/>)}</ul>} />
          )}
          {!isEmpty(memories) && (
            <BasicRow
            heading={"Memories:"}
            content={<ul>{memories.map(m => <MemoryItem key={`${today.id}-${m.id}`} memory={m}/>)}</ul>} />
          )}
        </div>
      </dl>
    </div>
  )
}