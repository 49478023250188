import * as React from "react";
import { Mutation } from "@apollo/client/react/components";
import { Formik } from "formik";
import { connect } from "react-redux";

import { signUpValidator } from "../../helpers/errorHelpers";
import { createUser } from "../../graphql/users/Mutations";
import ForgotPasswordLink from "./ForgotPasswordLink";
import { setAuth } from "../../redux/actions/auth";
import { addFlashMessage } from "../../redux/actions/flashMessages";

class RegisterView extends React.PureComponent {
  render() {
    const { dispatch, history } = this.props;
    return (
      <Mutation mutation={createUser}>
        {(createUser, { loading, error, data }) => (
          <div className="container mx-auto h-full flex justify-center items-center m-8">
            <div className="w-full max-w-xs content-center">
              {loading && <h2>Loading</h2>}
              {error && <h2>error</h2>}
              {data && data.UserAdd}
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                }}
                validationSchema={signUpValidator}
                onSubmit={(values) => {
                  createUser({
                    variables: {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.email,
                      password: values.password,
                    },
                    options: { fetchPolicy: "network-only" },
                  }).then((response) => {
                    const { token, flashMessage } = response.data.createUser;
                    if (flashMessage) {
                      dispatch(addFlashMessage(flashMessage));
                    }
                    if (token) {
                      dispatch(setAuth(true, token));
                      history.push("/me");
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form
                    className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                    onSubmit={handleSubmit}
                  >
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="firstName"
                      >
                        First Name
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        placeholder="John"
                      />
                      {touched.firstName && errors && errors.firstName && (
                        <p className="text-red-500 text-xs italic">
                          {errors.firstName}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="lastName"
                      >
                        Last Name
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        placeholder="Smith"
                      />
                      {touched.lastName && errors && errors.lastName && (
                        <p className="text-red-500 text-xs italic">
                          {errors.lastName}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder="John.Smith@gmail.com"
                      />
                      {touched.email && errors && errors.email && (
                        <p className="text-red-500 text-xs italic">
                          {errors.email}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        placeholder="Your P@33W0rd!"
                      />
                      {touched.password && errors && errors.password && (
                        <p className="text-red-500 text-xs italic">
                          {errors.password}
                        </p>
                      )}
                    </div>
                    <div className="flex items-center justify-between">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-12 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                      >
                        Get Started
                      </button>
                      <ForgotPasswordLink />
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </Mutation>
    );
  }
}

export default connect()(RegisterView);
