import React from "react";
import { useQuery } from "@apollo/client";
import { CURRENT_WEEK } from "../../graphql/weeks/Queries";
import { WeekView } from "./WeekView"

function CurrentWeek() {
  const { loading, error, data, refetch} = useQuery(CURRENT_WEEK, {
    fetchPolicy: "no-cache"
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    <WeekView {...data.currentWeek} refetch={refetch} />
  )
}

export default CurrentWeek;
