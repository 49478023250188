import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_WEEK } from "../../graphql/weeks/Queries";
import { WeekView } from "./WeekView"

function WeekFindById() {
  let { weekId } = useParams();
  const { loading, error, data, refetch} = useQuery(GET_WEEK, {
    variables: {id: weekId},
    fetchPolicy: "no-cache"
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    <WeekView {...data.week} refetch={refetch}/>
  )
}

export default WeekFindById;
