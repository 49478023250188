import React from 'react'
import { Objective } from "../Objectives/Objective";

export const SelectedObjective = ({objectives, selectedId, refetch}) => {
  const showObjective = objectives.filter((x) => x.id === selectedId)[0];
  return (
    <div>
      <h4 className="mb-4 border-t-4 border-gray-200 justify-self-auto text-center lg:mx-20">Your Objective</h4>
      <Objective key={showObjective.id} objective={showObjective} refetch={refetch} />
    </div>
  )
}