import React from "react";
import { Formik, Form  } from "formik";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { WEEK_ITEM_PROGRESS_UPDATE } from "../../graphql/weeks/Mutations";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";

const WeekItemComplete = ({
  weekId,
  itemId,
  refetch
}) => {
  const [week_item_progress_update] = useMutation(WEEK_ITEM_PROGRESS_UPDATE);
  const dispatch = useDispatch();
  return(
    <Formik
      initialValues={{
        weekId: String(weekId),
        itemId: String(itemId),
        progress: "1.0"
      }}
      onSubmit={values => {
        week_item_progress_update({
          variables: {
            weekId: values.weekId,
            itemId: values.itemId,
            progress: values.progress
          },
        }).then((response) => {
          const { flashMessage } = response.data.weekItemProgressUpdate;
          if (flashMessage) {
            dispatch(addFlashMessage(flashMessage));
          }
          refetch()
        });
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit} style={{display: "inline"}}>
          <button
            type="submit" >
            <CheckIcon
              width="25px"
              height="25px"
              className="inline-block"
            />
          </button>
        </Form>
      )}
    </Formik>
  )
};

export default connect()(WeekItemComplete);