import * as React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as Week } from "../../assets/icons/week.svg";
import { ReactComponent as Today } from "../../assets/icons/today.svg";

export function Sidebar() {
  return (
    <div className="bg-white rounded lg:col-span-1 m-2">
      <ul className="list-reset">
        <li className="block p-1 text-grey-darker text-left hover:bg-grey-lighter">
          <Link className="mx-8" to="/">
            <Home width="25px" height="25px" className="inline-block mx-2" />
            <p className="inline-block">Home</p>
          </Link>
        </li>
        <li className="block p-1 text-grey-darker text-left hover:bg-grey-lighter">
          <Link className="mx-8" to="/me/objectives">
            <Check width="25px" height="25px" className="inline-block mx-2" />
            <p className="inline-block">Objectives</p>
          </Link>
        </li>
        <li className="block p-1 text-grey-darker text-left hover:bg-grey-lighter">
          <Link className="mx-8" to="/me/objectives">
            <Week width="25px" height="25px" className="inline-block mx-2" />
            <p className="inline-block">Week</p>
          </Link>
        </li>
        <li className="block p-1 text-grey-darker text-left hover:bg-grey-lighter">
          <Link className="mx-8" to="/me/objectives">
            <Today width="25px" height="25px" className="inline-block mx-2" />
            <p className="inline-block">Today</p>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
