import gql from "graphql-tag";
import { FLASH_MESSAGE_FRAGMENT } from "../gqlFragments";

export const PROGRESS_UPDATE = gql`
  mutation progressUpdate($id: String!, $progress: String!) {
    progressUpdate(input: { progress: { id: $id, progress: $progress } }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;
