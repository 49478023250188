import gql from "graphql-tag";
import { WEEK_FRAGMENT } from "../gqlFragments";

export const me = gql`
  query {
    me {
      id
      email
      firstName
      lastName
    }
  }
`;

export const myObjectives = gql`
  query {
    objectives {
      id
      objective
      progress
      archive
      keyResults {
        id
        keyResult
        progress
        initiatives {
          id
          initiative
          progress
        }
      }
      weeks {
        ...WeekFragment
      }
    }
  }
  ${WEEK_FRAGMENT}
`;
