export function isLocalToken() {
  if (localStorage.getItem("token")) {
    return true;
  } else {
    return false;
  }
}

export default function auth(
  state = {
    isAuth: isLocalToken(),
  },
  action
) {
  switch (action.type) {
    case "SET_AUTH":
      return {
        ...state,
        isAuth: action.boolean,
      };
    default:
      return state;
  }
}
