import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import * as Yup from "yup";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { SubmitButton } from "../Shared/styles";
import { CREATE_INITIATIVES } from "../../graphql/initiatives/Mutations";

import { HeadingRow } from "../Shared/HeadingRow"
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";

const validationSchema = Yup.object().shape({
  initiatives: Yup.array().of(
    Yup.object().shape({
      initiative: Yup.string().required("Required"),
    })
  )
});

const getKeyResultErrors = (errors, i) => {
  const { [i]: err } = errors
  return err ? err["initiative"] : null
};

const InitForm = ({
  id,
  keyResult,
  keyResultId,
  nextStep,
  keyResultLength,
  openTab
}) => {
  const [createInitiatives] = useMutation(CREATE_INITIATIVES);
  const dispatch = useDispatch();
  const currentKeyResult = keyResult ? keyResult.keyResult : null
  const keyResults = keyResult && <h4 className="mb-4">Current Key Result: {currentKeyResult}</h4>
  const completed = !isEmpty(keyResult.initiatives)
  const steps = (openTab +1) === keyResultLength ? "Complete Objective" : "Next Key Result"

  return(
    <div>
      {completed ? <div className="text-center">{"Initiative Completed"}</div> : (
      <Formik
        initialValues={{
          initiatives:[
            {
              id: id || '',
              initiative: '',
              key_result_id: keyResultId
            },
            {
              id: id || '',
              initiative: '',
              key_result_id: keyResultId
            },
            {
              id: id || '',
              initiative: '',
              key_result_id: keyResultId
            }
          ]
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => {
          createInitiatives({
            variables: {
              initiatives: values.initiatives
            },
          }).then((response) => {
            const { flashMessage } = response.data.createInitiatives;
            if (flashMessage) {
              dispatch(addFlashMessage(flashMessage));
            }
            nextStep()
          });
        }}
      >
      {({ values, errors }) => (
            <Form>
              <HeadingRow
                  heading={keyResults}
                  content={
              <FieldArray name="initiatives">
                {(arrayHelpers) => (
                  <div>
                    {values.initiatives.length > 0 &&
                      values.initiatives.map((initiative, index) => (
                        <div key={index}>
                          <div className="sm:col-start-2 col-span-12 sm:col-span-10">
                            <Field
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-5/6 my-2 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                              name={`initiatives.${index}.initiative`}
                              type="text"
                              placeholder="Initiative"
                            />
                            {values.initiatives.length > 1 && (
                              <button
                                type="button"
                                onClick={(e) => arrayHelpers.remove(index)} >
                                <DeleteIcon
                                  width="25px"
                                  height="25px"
                                  className="inline-block"
                                />
                            </button>
                            )}
                            {errors.initiatives && (
                              <p className="text-red-500 text-xs italic">
                               {getKeyResultErrors(errors.initiatives, index) && getKeyResultErrors(errors.initiatives, index)}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                      {values.initiatives.length <= 4 &&
                        <button
                          type="button"
                          className="text-xs bg-green-500 hover:bg-green-700 text-white font-bold py-2 my-2 px-4 rounded"
                          onClick={() => arrayHelpers.push({ id: id || '', initiative: '', key_result_id: keyResultId})}
                        >
                          Add another Initiative
                        </button>}
                  </div>
                )}
              </FieldArray>}
              />
              <div className="text-center my-8">
                <SubmitButton type="submit">{steps}</SubmitButton>
              </div>
            </Form>
      )}
      </Formik>
      )}
    </div>
  )
};

export default connect()(InitForm);