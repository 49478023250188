import gql from "graphql-tag";
import { FLASH_MESSAGE_FRAGMENT } from "../gqlFragments";

export const CREATE_INITIATIVES = gql`
  mutation createInitiative($initiatives: [JSON!]) {
    createInitiatives(input: { initiatives: $initiatives }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const UPDATE_INITIATIVE = gql`
  mutation updateInitiative($id: String!, $initiative: String!) {
    updateInitiative(input: { id: $id, initiative: $initiative }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;
