import gql from "graphql-tag";
import { FLASH_MESSAGE_FRAGMENT } from "../gqlFragments";

export const UPDATE_OBJECTIVE = gql`
  mutation updateObjective($id: String, $objective: String!, $action: String!) {
    updateObjective(
      input: { objective: { id: $id, objective: $objective, action: $action } }
    ) {
      objectiveReturn {
        flashMessage {
          ...FlashMessageFragment
        }
        objective {
          id
        }
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const OBJECTIVE_ARCHIVE = gql`
  mutation objectiveArchive($objective_id: String) {
    objectiveArchive(
      input: { objectiveId: $objective_id }
    ) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;
