import React from "react";
import { Formik, Form } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { connect, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { GET_WEEK } from "../../graphql/weeks/Queries";
import { ItemsSection } from "../Weeks/ItemsSection"
import { ArrayForm } from "../Shared/ArrayForm";
import * as Yup from "yup";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { SubmitButton } from "../Shared/styles";
import { TODAY_CREATE } from "../../graphql/today/Mutations";

const validationSchema = Yup.object().shape({
  todos: Yup.array().of(
    Yup.object().shape({
      todo: Yup.string().required("Required"),
    })
  ),
  gratefuls: Yup.array().of(
    Yup.object().shape({
      grateful: Yup.string().required("Required"),
    })
  )
});

const MorningForm = () => {
  const { weekId } = useParams();
  const [todayCreate] = useMutation(TODAY_CREATE);
  const { loading, error, data, refetch} = useQuery(GET_WEEK, {
    variables: {id: weekId}
  });
  const dispatch = useDispatch();
  const history = useHistory();

  if (loading) return null;
  if (error) return `Error! ${error}`;
  const {items} = data.week

  return(
    <div className="grid grid-cols-12 bg-gray-400 min-h-screen h-auto">
      <div className="sm:col-start-2 col-span-12 sm:col-span-10">
        <div className="grid grid-cols-8">
          <div className="bg-white rounded m-2 col-span-8 px-8 pt-6 pb-8 mb-4">
            <h1 className="mb-4 text-center">Morning Check-In!</h1>
            <h4 className="mb-4 border-t-4 border-gray-200 justify-self-auto text-center lg:mx-40">What are you most important to-do for today? (5 Max)</h4>
            <div className="grid grid-cols-10">
              <div className="invisible md:visible order-2 m-2 col-span-10 lg:col-span-4">
                <h4 className="mb-4 border-t-4 border-gray-200 justify-self-auto text-center lg:mx-8">Weekly To-dos</h4>
                <ItemsSection weekId={weekId} items={items} refetch={refetch} />
              </div>
              <div className="order-1 m-2 col-span-10 lg:col-span-6">
                <Formik
              initialValues={{
                  todos:[
                          {
                            id: 1,
                            todo: ''
                          },
                          {
                            id: 2,
                            todo: ''
                          },
                          {
                            id: 3,
                            todo: ''
                          }
                        ],
                  gratefuls:[
                          {
                            id: 1,
                            grateful: ''
                          }
                        ],
                }}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={values => {
                todayCreate({
                  variables: {
                    weekId: weekId,
                    todos: values.todos,
                    gratefuls: values.gratefuls,
                  },
                }).then((response) => {
                  const { flashMessage } = response.data.todayCreate;
                  if (flashMessage) {
                    dispatch(addFlashMessage(flashMessage));
                  }
                  history.push(`/week/${weekId}`);
                });
              }}
            >
            {({ values, errors }) => (
              <Form>
                <ArrayForm
                  itemNames="todos"
                  itemName="todo"
                  items={values.todos}
                  errors={errors.todos}
                  label="Todos"
                  placeholder="To do"
                />
                <ArrayForm
                  itemNames="gratefuls"
                  itemName="grateful"
                  items={values.gratefuls}
                  errors={errors.gratefuls}
                  label="Gratefuls"
                  placeholder="Grateful"
                />
                <div className="text-center my-8"><SubmitButton type="submit">Start Your Day</SubmitButton></div>
              </Form>
            )}
            </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default connect()(MorningForm);