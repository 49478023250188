import React from "react";
import { Formik, Form, Field  } from "formik";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { TODAY_NOTES_UPDATE } from "../../graphql/today/Mutations";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";

const validationSchema = Yup.object().shape({
  notes: Yup
    .string()
    .required("Required"),
});

const NotesAdd = ({
  todayId,
  notes,
  noteEdit,
  setEditingFalse,
  refetch,
  editing
}) => {
  const [today_notes_update] = useMutation(TODAY_NOTES_UPDATE);
  const dispatch = useDispatch();
  const rowSize = editing ? 7 : 1
  return(
    <Formik
      initialValues={{
        todayId: String(todayId),
        notes: notes || '',
        noteEdit: noteEdit || false
      }}
      validationSchema={validationSchema}
      onSubmit={values => {
        today_notes_update({
          variables: {
            todayId: values.todayId,
            note: values.notes,
            noteEdit: values.noteEdit
          },
        }).then((response) => {
          const { flashMessage } = response.data.todayNotesUpdate;
          if (flashMessage) {
            dispatch(addFlashMessage(flashMessage));
          }
          setEditingFalse()
          refetch()
        });
      }}
    >
      {({handleSubmit, errors}) => (
        <Form onSubmit={handleSubmit} style={{display: "inline"}}>
          <Field
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-1/2 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            name="notes"
            type="text"
            placeholder="Add to your Notes"
            component="textarea"
            rows={rowSize}
          />
          <button
            className="inline-flex"
            type="submit" >
            <PlusIcon
              width="25px"
              height="25px"
              className="inline-block"
            />
          </button>
          <button
            className="inline-flex"
            onClick={() => setEditingFalse()} >
            <DeleteIcon
              width="25px"
              height="25px"
              className="inline-block"
            />
          </button>
          {errors.notes && (
            <p className="text-red-500 text-xs italic">
              {errors.notes}
            </p>
          )}
        </Form>
      )}
    </Formik>
  )
};

export default connect()(NotesAdd);