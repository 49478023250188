import React, {useState } from "react";
import { Formik, Form, Field } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { SubmitButton } from "../Shared/styles";
import { WEEK_CREATE_ITEMS } from "../../graphql/weeks/Mutations";
import { myObjectives } from "../../graphql/users/Queries";
import { SelectedObjective } from "../Shared/SelectedObjective";

import { ArrayForm } from "../Shared/ArrayForm";
import { HeadingRow } from "../Shared/HeadingRow"

const validationSchema = Yup.object().shape({
  items: Yup.array().of(
    Yup.object().shape({
      item: Yup.string().required("Required"),
    })
  )
});

const selectOptions = (objects) => {
 var options_array = []
  objects.map(objective => (
    options_array.push({value: objective.id, label: objective.objective})
    )
  )
  return options_array
}

const CreateForm = () => {
  const [selectedObjectiveId, setSelectedObjectiveId] = useState(null)
  const { loading, data, refetch } = useQuery(myObjectives);
  const [weekCreateItems] = useMutation(WEEK_CREATE_ITEMS);
  const dispatch = useDispatch();
  const history = useHistory();

  if (loading) return null;
  const options = selectOptions(data.objectives)

  return(
    <div className="grid grid-cols-12 bg-gray-400 min-h-screen h-auto">
      <div className="sm:col-start-2 col-span-12 sm:col-span-10">
        <div className="grid grid-cols-6">
          <div className="bg-white rounded m-2 col-span-8 px-8 pt-6 pb-8 mb-4">
            <div>
              <h1 className="mb-4 text-center">Start a new Week!</h1>
              <h4 className="mb-4 border-t-4 border-gray-200 text-center lg:mx-40">Let's plan for the week ahead! What are the most important tasks for the week ahead? (5 Max)</h4>
              <div className="grid grid-cols-10">
                <div className="invisible md:visible order-2 m-2 col-span-10 lg:col-span-4">
                  <SelectedObjective objectives={data.objectives} selectedId={selectedObjectiveId || options[0].value} refetch={refetch} />
                </div>
                <div className="order-1 m-2 col-span-10 lg:col-span-6">
                  <Formik
                initialValues={
                  {
                    objectiveId: options[0].value,
                    items:[
                            {
                              id: 1,
                              item: ''
                            },
                            {
                              id: 2,
                              item: ''
                            },
                            {
                              id: 3,
                              item: ''
                            }
                          ],
                    commitment: ''
                  }
                }
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={values => {
                  weekCreateItems({
                    variables: {
                      objectiveId: values.objectiveId,
                      items: values.items,
                      commitment: values.commitment
                    },
                  }).then((response) => {
                    const { weekReturn } = response.data.weekCreate;
                    const { flashMessage, week } = weekReturn;
                    if (flashMessage) {
                      dispatch(addFlashMessage(flashMessage));
                    }
                    history.push(`/week/${week.id}`);
                    localStorage.setItem("currentWeek", week.id)
                  });
                }}
              >
              {({ values, errors }) => (
                <Form>
                  <HeadingRow
                    heading={"Which objective would you like to focus on this week?"}
                    content={
                      <Field
                        as="select"
                        name="objectiveId"
                        onChange={(e) => (setSelectedObjectiveId(e.target.value))}
                        >
                        {options.map(option => (
                          <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                      </Field>
                    }
                    />
                  <ArrayForm
                    itemNames="items"
                    itemName="item"
                    items={values.items}
                    errors={errors.items}
                    label="Weekly To-do"
                    placeholder="To-do"
                    />
                  <HeadingRow
                    heading={"How will you be productive this week?"}
                    content={
                      <Field
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-3/4 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                        name="commitment"
                        type="text"
                        placeholder="How will you be productive this week?"
                        />
                    }
                    />
                  <div className="text-center my-8"><SubmitButton type="submit">Start Your Week</SubmitButton></div>
                </Form>

              )}
              </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default connect()(CreateForm);