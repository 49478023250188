import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import InitForm from "./Form"

const Content = ({id, keyResult, nextStep, openTab, refetch, keyResultLength}) => {
  return (
    <div className={openTab === id ? "block" : "hidden"} id="link3">
      {keyResult &&
        <InitForm
          id={id}
          keyResult={keyResult}
          keyResultId={keyResult.id}
          nextStep={nextStep}
          refetch={refetch}
          openTab={openTab}
          keyResultLength={keyResultLength}
        />}
    </div>
  )
}

const ActionTabs = ({id, keyResult, color, openTab, setOpenTab}) => {
  return (
    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
      <a
        className={
          "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
          (openTab === id
            ? "text-white bg-" + color + "-600"
            : "text-" + color + "-600 bg-white")
        }
        onClick={e => {
          e.preventDefault();
          setOpenTab(id, keyResult.id);
        }}
        data-toggle="tab"
        href="#link3"
        role="tablist"
      >
        Key Result {id+1}
      </a>
    </li>
  )
}

const Tabs = ({ color, keyResults, refetch }) => {
  const history = useHistory();
  const [openTab, setOpenTab] = useState(
    JSON.parse(localStorage.getItem("openTab")) || 0
  );
  const localkeyResultId = (id) => {
    setOpenTab(id);
  };
  const keyResultLength = keyResults && keyResults.length
  const nextStep = () => {
    if(keyResults.length === (openTab + 1)) { //go back to dashboard
      localStorage.setItem("openTab", 0);
      localStorage.setItem("objectiveStep", 1);
      history.push("/");
    } else {
      localStorage.setItem("openTab", openTab+1);
      setOpenTab(openTab+1);
      refetch()
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full">
        <ul
          className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
          role="tablist"
        >
          {keyResults && (keyResults.map((k, i) =>
            <ActionTabs
              key={k.id}
              id={i}
              keyResult={k}
              color={color}
              openTab={openTab}
              setOpenTab={(id) => localkeyResultId(id)}
              />
          ))}
        </ul>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
          <div className="px-4 py-5 flex-auto lg:mx-48">
            <div className="tab-content tab-space">
              {keyResults && (keyResults.map((k, i) =>
                <Content
                  key={k.id}
                  id={i}
                  keyResult={k}
                  color={color}
                  openTab={openTab}
                  nextStep={() => nextStep()}
                  refetch={refetch}
                  keyResultLength={keyResultLength}
                  />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const KeyResultForm = ({
  keyResults,
  refetch
}) => {

  return (
    <div className="bg-white rounded px-8 pt-6 pb-8 mb-4">
      <h3 className="mb-4 text-center">Step 3 - Break Down your Key Results</h3>
      <h4 className="mb-4 border-t-4 text-center lg:mx-40">We recomend between 3 to 5 Initiatives. Initiatives are action you need to do to complete your Key Results. I.e Make 20 Sales calls a day. The Hard Work as we say it.</h4>
      <Tabs color="blue" keyResults={keyResults} refetch={refetch}/>
    </div>
  )
};

export default KeyResultForm;
