import React from 'react'
import { Formik, Form } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { connect, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { ArrayForm } from "../Shared/ArrayForm";
import { TodosSection } from "./TodosSection"
import * as Yup from "yup";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { SubmitButton } from "../Shared/styles";

import { TODAY_COMPLETE } from "../../graphql/today/Mutations";
import { GET_WEEK } from "../../graphql/weeks/Queries";
import { GET_TODAY } from "../../graphql/today/Queries";

const validationSchema = Yup.object().shape({
  achievements: Yup.array().of(
    Yup.object().shape({
      achievement: Yup.string().required("Required"),
    })
  ),
  lessons: Yup.array().of(
    Yup.object().shape({
      lesson: Yup.string().required("Required"),
    })
  ),
  memories: Yup.array().of(
    Yup.object().shape({
      memory: Yup.string().required("Required"),
    })
  )
});

export const EveningForm = () => {
  const { todayId, weekId } = useParams();
    const { loading, error, data, refetch} = useQuery(GET_TODAY, {
    variables: {id: todayId}
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [todayComplete] = useMutation(TODAY_COMPLETE, {
      refetchQueries: [{
        query: GET_WEEK,
        variables: {id: weekId}
      }]
    });

  if (loading) return null;
  if (error) return `Error! ${error}`;
  const {todos} = data.today

  return(
    <div className="grid grid-cols-12 bg-gray-400 min-h-screen h-auto">
      <div className="sm:col-start-2 col-span-12 sm:col-span-10">
        <div className="grid grid-cols-8">
          <div className="bg-white rounded m-2 col-span-8 px-8 pt-6 pb-8 mb-4">
            <h1 className="mb-4 text-center">Evening Check-In!</h1>
            <h4 className="mb-4 border-t-4 border-gray-200 justify-self-auto text-center lg:mx-40">Wrap up your day</h4>
            <div className="grid grid-cols-10">
              <div className="invisible md:visible order-2 m-2 col-span-10 lg:col-span-4">
                <h4 className="mb-4 border-t-4 border-gray-200 justify-self-auto text-center lg:mx-8">Today's To-dos</h4>
                <TodosSection todayId={todayId} todos={todos} refetch={refetch} />
              </div>
              <div className="order-1 m-2 col-span-10 lg:col-span-6">
                <Formik
                  initialValues={{
                      achievements:[
                              {
                                id: 1,
                                achievement: ''
                              }
                            ],
                      lessons:[
                              {
                                id: 1,
                                lesson: ''
                              }
                            ],
                      memories:[
                              {
                                id: 1,
                                memory: ''
                              }
                            ],
                    }}
                  validationSchema={validationSchema}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={values => {
                    todayComplete({
                      variables: {
                        todayId: todayId,
                        weekId: weekId,
                        achievements: values.achievements,
                        lessons: values.lessons,
                        memories: values.memories,
                      },
                    }).then((response) => {
                      const { flashMessage } = response.data.todayComplete;
                      if (flashMessage) {
                        dispatch(addFlashMessage(flashMessage));
                      }
                      history.push(`/week/${weekId}`);
                    });
                  }}
                >
                {({ values, errors }) =>(
                  <Form>
                    <ArrayForm
                      itemNames="achievements"
                      itemName="achievement"
                      items={values.achievements}
                      errors={errors.achievements}
                      label="Achievements"
                      placeholder="Achievement"
                      />
                    <ArrayForm
                      itemNames="lessons"
                      itemName="lesson"
                      items={values.lessons}
                      errors={errors.lessons}
                      label="Lessons"
                      placeholder="Lesson"
                      />
                    <ArrayForm
                      itemNames="memories"
                      itemName="memory"
                      items={values.memories}
                      errors={errors.memories}
                      label="Memories"
                      placeholder="Memory"
                      />
                    <div className="text-center my-8"><SubmitButton type="submit">Complete Your Day</SubmitButton></div>
                  </Form>
                )}
              </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect()(EveningForm);