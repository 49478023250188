import React from "react";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { PROGRESS_UPDATE } from "../../graphql/shared/Mutations";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";

const InitCompleteForm = ({
  id,
  refetch
}) => {
  const [update_progress] = useMutation(PROGRESS_UPDATE);
  const dispatch = useDispatch();
  return(
    <div>
      <Formik
        initialValues={{
          id: id || "",
          progress: "1.0"
        }}
        onSubmit={values => {
          update_progress({
            variables: {
              id: values.id,
              progress: values.progress
            },
          }).then((response) => {
            const { flashMessage } = response.data.progressUpdate;
            if (flashMessage) {
              dispatch(addFlashMessage(flashMessage));
            }
            refetch()
          });
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <button
              type="submit" >
              <CheckIcon
                width="25px"
                height="25px"
                className="inline-block"
              />
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
};

export default connect()(InitCompleteForm);