import React, { Component } from "react";
import classnames from "classnames";

export default class FlashMessage extends Component {
  componentDidMount() {
    setTimeout(() => this.clearFlashMessage(), 5000);
  }

  componentWillUnmount() {
    clearTimeout();
  }

  clearFlashMessage = () => {
    this.props.deleteFlashMessage(this.props.message.id);
  };

  render() {
    const { type, message } = this.props.message;

    return (
      <div
        className={classnames("text-center border px-4 py-3 my-2 rounded", {
          "bg-red-100 border-red-400 text-red-700": type === "error",
          "bg-blue-100 border-blue-400 text-blue-700": type === "success",
        })}
      >
        <strong style={{ textTransform: "capitalize" }} className="font-bold">
          {type}{" "}
        </strong>
        <span className="inline-block m-2">{message}</span>
        <span className="relative inline-block top-0 bottom-0 right-0 px-4">
          <svg
            className={classnames("fill-current h-6 w-6", {
              "text-red-500": type === "error",
              "text-blue-500": type === "success",
            })}
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            onClick={() => this.clearFlashMessage()}
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </div>
    );
  }
}
