import gql from "graphql-tag";
import { FLASH_MESSAGE_FRAGMENT } from "../gqlFragments";

export const TODAY_CREATE = gql`
  mutation TodayCreate($weekId: String!, $todos: [JSON!], $gratefuls: [JSON!]) {
    todayCreate(input: { today: { weekId: $weekId, todos: $todos, gratefuls: $gratefuls } }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const TODAY_COMPLETE = gql`
  mutation TodayComplete(
    $todayId: String!
    $weekId: String!
    $achievements: [JSON!]
    $lessons: [JSON!]
    $memories: [JSON!]
  ) {
    todayComplete(
      input: {
        today: {
          todayId: $todayId
          weekId: $weekId
          achievements: $achievements
          lessons: $lessons
          memories: $memories
        }
      }
    ) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const TODAY_TODO_PROGRESS_UPDATE = gql`
  mutation TodayTodoProgressUpdate($todayId: String!, $todoId: String!, $progress: String!) {
    todayTodoProgressUpdate(input: { todayId: $todayId, todoId: $todoId, progress: $progress }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const TODAY_TODO_ADD = gql`
  mutation TodayTodoAdd($todayId: String!, $todo: JSON!) {
    todayTodoAdd(input: { todayId: $todayId, todo: $todo }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const TODAY_TODO_DELETE = gql`
  mutation TodayTodoDelete($todayId: String!, $todoId: String!) {
    todayTodoDelete(input: { todayId: $todayId, todoId: $todoId }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const TODAY_TODO_UPDATE = gql`
  mutation TodayTodoUpdate($todayId: String!, $todoId: String!, $todo: String!) {
    todayTodoUpdate(input: { todayId: $todayId, todoId: $todoId, todo: $todo }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const TODAY_NOTES_UPDATE = gql`
  mutation todayNotesUpdate($todayId: String!, $note: String!, $noteEdit: Boolean!) {
    todayNotesUpdate(input: { todayId: $todayId, note: $note, noteEdit: $noteEdit }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;
