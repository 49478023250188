import React, { Component } from "react";
import FlashMessage from "./FlashMessage";
import { connect } from "react-redux";
import { deleteFlashMessage } from "../../redux/actions/flashMessages";

class MessagesList extends Component {
  render() {
    const messages = this.props.messages.map((message) => (
      <FlashMessage
        key={message.id}
        message={message}
        deleteFlashMessage={this.props.deleteFlashMessage}
      />
    ));
    return <div className="fixed top-0 right-0 my-16 mx-4">{messages.length > 0 && messages}</div>;
  }
}

function mapStateToProps(state) {
  return { messages: state.flashMessages };
}

export default connect(mapStateToProps, { deleteFlashMessage })(MessagesList);
