import React, { useState } from 'react'

import ProgressForm from "./ProgressForm";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { circleWithPercent } from "./CircleWithPercent";
import { progressCircle } from "./ProgressColor";
import { EditForm } from "../Initiatives";
import InitCompleteForm from '../Initiatives/InitCompleteForm';

export const InitiativeRow = ({initiative, refetch}) => {
  const circle = circleWithPercent(initiative.progress * 100, progressCircle(initiative.progress.toFixed(2)));
  const [isEditing, setEditing] = useState(false);
  const [isEditingInitiative, setEditingInitiative] = useState(false);
  const editing = isEditing ? (
    <ProgressForm
      id={initiative.id}
      type={"initiative"}
      progress={initiative.progress}
      setEditingFalse={() => setEditing(false)}
      refetch={refetch}
    />
  ) : (
    <span className="inline-flex">{circle}</span>
  );
  const editingInitiative = isEditingInitiative ? (
    <EditForm
      id={initiative.id}
      initiative={initiative.initiative}
      setEditingFalse={() => setEditingInitiative(false)}
      refetch={refetch}
    />
  ) : (
    <p className="inline-flex flex-grow">{initiative.initiative}</p>
  );
  return (
      <div className="inline-flex">
        {editing}
        {setEditing && (
          <div className="inline-flex mx-1">
            <button
            className="inline-flex"
            onClick={() => setEditing(!isEditing)}>
            {isEditing === false && (
              <div className="inline-flex">
                <EditIcon
                  width="25px"
                  height="25px"
                  className="inline-block mx-1"
                />
              </div>
            )}
          </button>
          {isEditing === false && (
            <InitCompleteForm
              id={initiative.id}
              type={"initiative"}
              refetch={refetch}
            />)}
          </div>
          )}
        {editingInitiative}
        {setEditingInitiative && isEditingInitiative === false &&
          (<div className="inline-flex content-end">
              <button
                className="inline-flex"
                onClick={() => setEditingInitiative(!isEditingInitiative)}>
                <EditIcon
                  width="25px"
                  height="25px"
                  className="inline-block"
                />
              </button>
            </div>
          )}
      </div>
  )
}
