import React from "react";
import { Formik, Form, Field } from "formik";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { PROGRESS_UPDATE } from "../../graphql/shared/Mutations";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";

const ProgressForm = ({
  id,
  progress,
  setEditingFalse,
  refetch
}) => {
  const [update_progress] = useMutation(PROGRESS_UPDATE);
  const dispatch = useDispatch();
  return(
    <div>
      <Formik
        initialValues={{
          id: id || "",
          progress: String(progress.toFixed(1)) || "0.0"
        }}
        onSubmit={values => {
          update_progress({
            variables: {
              id: values.id,
              progress: values.progress
            },
          }).then((response) => {
            const { flashMessage } = response.data.progressUpdate;
            if (flashMessage) {
              dispatch(addFlashMessage(flashMessage));
            }
            setEditingFalse()
            refetch()
          });
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Field as="select" name="progress">
              <option value="0.0">0%</option>
              <option value="0.1">10%</option>
              <option value="0.2">20%</option>
              <option value="0.3">30%</option>
              <option value="0.4">40%</option>
              <option value="0.5">50%</option>
              <option value="0.6">60%</option>
              <option value="0.7">70%</option>
              <option value="0.8">80%</option>
              <option value="0.9">90%</option>
              <option value="1.0">100%</option>
            </Field>
              <button
                type="submit" >
                <CheckIcon
                  width="25px"
                  height="25px"
                  className="inline-block"
                />
              </button>
              <button
                onClick={() => setEditingFalse()} >
                <CautionIcon
                  width="25px"
                  height="25px"
                  className="inline-block"
                />
              </button>

          </Form>
        )}
      </Formik>
    </div>
  )
};

export default connect()(ProgressForm);