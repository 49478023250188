import React from 'react';
import { FieldArray, Field } from "formik";
import { HeadingRow } from "../Shared/HeadingRow"
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";

const getKeyResultErrors = (errors, i, itemName) => {
  const { [i]: err } = errors
  return err ? err[itemName] : null
};

export const ArrayForm = ({itemNames, itemName, items, label, placeholder, errors}) => {
  const newItem = {id: items.length + 1}
  const capitalizeItemName = placeholder[0].toUpperCase() + placeholder.slice(1)

  newItem[itemName] = ''
  return(
    <HeadingRow
      heading={label}
      content={
      <FieldArray name={`${itemNames}`}>{(arrayHelpers) => (
        <div>
          {items && items.length > 0 &&
            items.map((items, index) => (
              <div key={index} className="sm:col-start-2 col-span-12 sm:col-span-10">
                <label
                  className="block text-gray-600 md:text-left mb-1 md:mb-0 pr-4"
                  htmlFor={`${itemNames}.${index}.${itemName}`}
                >

                </label>
                <Field
                  className="bg-gray-200 appearance-none border-2 border-gray-200
                    rounded w-3/4 py-2 px-4 text-gray-700 leading-tight
                    focus:outline-none focus:bg-white focus:border-blue-500 my-2"
                  name={`${itemNames}.${index}.${itemName}`}
                  type="text"
                  placeholder={placeholder}
                />
                <button
                  type="button"
                  onClick={() => arrayHelpers.remove(index)}>
                  <DeleteIcon
                    width="25px"
                    height="25px"
                    className="inline-block"
                  />
                </button>
                {errors && (
                  <p className="text-red-500 text-xs italic">
                    {getKeyResultErrors(errors, index, itemName) && getKeyResultErrors(errors, index, itemName)}
                  </p>
                )}
              </div>
            ))}
            {items.length <= 4 &&
              <button
                type="button"
                className="text-xs bg-green-500 hover:bg-green-700 text-white font-bold py-2 my-2 px-4 rounded"
                onClick={() => arrayHelpers.push(newItem)}
              >
                Add another {capitalizeItemName}
              </button>}
        </div>
      )}
    </FieldArray>
      }
      />

  )
}