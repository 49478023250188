import React from "react";
import moment from 'moment';
import { Link } from "react-router-dom";

import { HeadingRow } from "../Shared/HeadingRow"
import { BasicRow } from "../Shared/BasicRow"
import { progressColor, progressCircle } from "../Shared/ProgressColor";
import { circleWithPercent } from "../Shared/CircleWithPercent"
import { ItemsSection } from "./ItemsSection"
import { NotesSection } from "./NotesSection"

export const WeekTodosView = ({id, items, progress, notes, createdAt, refetch }) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const viewWeek = `/week/${id}`
  const classNameProgress = `overflow-hidden h-2 mb-4 text-xs flex rounded ` + progressColor(progress.toFixed(2));
  const circle = circleWithPercent((progress * 100), progressCircle(progress.toFixed(2)))

  return (
    <div>
      <HeadingRow
        heading={<p>{circle} Week of {moment(createdAt).utcOffset(timezone).format("LL")}</p>}
        content={
            <Link
              className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              to={viewWeek}>View Week</Link>
            }
      />
      <dl>
        <BasicRow
          heading={"Weekly To-dos:"}
          content={
            <div>
              <div className="relative pt-1">
                <div className={classNameProgress}></div>
              </div>
              <ItemsSection weekId={id} items={items} refetch={refetch} />
            </div>
          }
        />
        <BasicRow
          heading={"Notes:"}
          content={
            <div>
              <NotesSection weekId={id} notes={notes} refetch={refetch} />
              <div className="relative pt-1">
                <div className={classNameProgress}></div>
              </div>
            </div>
          }
        />
      </dl>
    </div>
  )
}
