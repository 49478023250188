import React from "react";
import { TodayItem } from "../Today"
import { Link } from "react-router-dom";

import { HeadingRow } from "../Shared/HeadingRow"
import { BasicRow } from "../Shared/BasicRow"
import { progressColor, progressCircle } from "../Shared/ProgressColor";
import { circleWithPercent } from "../Shared/CircleWithPercent"
import { ItemsSection } from "./ItemsSection"
import { NotesSection } from "./NotesSection"

export const WeekView = ({id, items, todays, planExecution, effective, improvement, progress, refetch, objectiveId, notes }) => {
  const morninglink = `/today/${id}/morning`
  const finishWeekLink = `/week/${id}/complete/${objectiveId}`
  const newWeek = `/weekly_plan`
  const allWeek = `/weeks/${objectiveId}`
  const classNameProgress = `overflow-hidden h-2 mb-4 text-xs flex rounded ` + progressColor(progress.toFixed(2));
  const circle = circleWithPercent((progress * 100), progressCircle(progress.toFixed(2)))

  return (
    <div className="grid grid-cols-12 bg-gray-400 min-h-screen h-auto">
      <div className="sm:col-start-2 col-span-12 sm:col-span-10">
        <div className="grid grid-cols-8 m-2">
          <div className="rounded m-2 col-span-8">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="bg-white rounded px-8 pt-6 pb-8 mb-4">
                <HeadingRow
                  heading={<p>{circle} Your Current Week</p>}
                  content=
                    {
                    <div>
                      {effective || improvement || planExecution ? (
                        <Link
                          className="text-xs bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                          to={newWeek}>Start A New Week
                        </Link>
                      ) : (
                        <Link
                          className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                          to={finishWeekLink}>Wrap up your Week
                        </Link>
                      )}
                      <Link
                        className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 ml-4 px-4 rounded"
                        to={allWeek}>View All Weeks
                      </Link>
                    </div>
                    }
                />

                  <dl>
                    <BasicRow
                      heading={"Weekly To-dos:"}
                      content={
                        <div>
                          <div className="relative pt-1">
                            <div className={classNameProgress}></div>
                          </div>
                          <ItemsSection weekId={id} items={items} refetch={refetch} />
                        </div>

                      }
                    />
                    <BasicRow
                      heading={"Notes:"}
                      content={
                        <NotesSection weekId={id} notes={notes} refetch={refetch} />
                      }
                    />
                    { effective && <BasicRow heading={"How effective were you this week?"} content={effective} /> }
                    { improvement && <BasicRow heading={"Things you could have improvement:"} content={improvement} /> }
                    { planExecution && <BasicRow heading={"How well did you execute your plan?"} content={planExecution} /> }
                  </dl>
                </div>
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="bg-white rounded px-8 pt-6 pb-8 mb-4">
                    <div className="border-b">
                      <BasicRow
                        heading={"Daily Check-Ins:"}
                        content={todays.length >= 5 ? (
                            <Link className="text-xs bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" to={finishWeekLink}>Wrap up your Week</Link>
                          ): (
                            <Link className="text-xs bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" to={morninglink}>Start a Check-In</Link>
                          )} />
                    </div>
                    { todays && todays.map(t => <TodayItem key={t.id} today={t} weekId={id} refetch={refetch} />) }
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
