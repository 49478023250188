import React from "react";
import { Mutation } from "@apollo/client/react/components";
import { Formik } from "formik";
import { connect } from "react-redux";

import { setAuth } from "../../redux/actions/auth";
import { addFlashMessage } from "../../redux/actions/flashMessages";

import { loginValidator } from "../../helpers/errorHelpers";
import { signinUser } from "../../graphql/users/Mutations";
import ForgotPasswordLink from "./ForgotPasswordLink";

class LoginView extends React.PureComponent {
  settoken = (token) => {
    localStorage.setItem("token", JSON.stringify(token));
  };

  render() {
    const { location, history, dispatch } = this.props;
    const { state } = location;
    const to = state ? (state.from ? state.from.pathname : "/me") : "/me";
    return (
      <Mutation mutation={signinUser}>
        {(signinUser, { loading, error, data }) => (
          <div className="container mx-auto h-full flex justify-center items-center m-8">
            <div className="w-full max-w-xs content-center">
              {loading && <h2>Loading</h2>}
              {error && <h2>error</h2>}
              {data && data.UserAdd}
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={loginValidator}
                onSubmit={(values) => {
                  signinUser({
                    variables: {
                      email: values.email,
                      password: values.password,
                    },
                    options: { fetchPolicy: "network-only" },
                  })
                    .then((response) => {
                      const { token, flashMessage } = response.data.signinUser;
                      if (token) {
                        dispatch(setAuth(true, token));
                        history.push(to);
                      }
                      if (flashMessage) {
                        dispatch(addFlashMessage(flashMessage));
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form
                    className="bg-white shadow-lg rounded px-8 pt-6 pb-8 mb-4"
                    onSubmit={handleSubmit}
                  >
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder="john.smith@gmail.com"
                      />
                      {touched.email && errors && errors.email && (
                        <p className="text-red-500 text-xs italic">
                          {errors.email}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        placeholder="Your P@33W0rd!"
                      />
                      {touched.password && errors && errors.password && (
                        <p className="text-red-500 text-xs italic">
                          {errors.password}
                        </p>
                      )}
                    </div>
                    <div className="flex items-center justify-between">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-12 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                      >
                        Login
                      </button>
                      <ForgotPasswordLink />
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </Mutation>
    );
  }
}

export default connect()(LoginView);
