import React, { useState } from 'react'

import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { progressCircle } from "../Shared/ProgressColor";
import { circleWithPercent } from "../Shared/CircleWithPercent";
import WeekItemProgressForm from "./WeekItemProgressForm";
import WeekItemComplete from "./WeekItemComplete";
import WeekItemDelete from "./WeekItemDelete";
import WeekItemEdit from "./WeekItemEdit"

export const ListItem = ({item, weekId, refetch}) => {
  const [isEditing, setEditing] = useState(false);
  const [isEditingItem, setEditingItem] = useState(false);
  const circle = circleWithPercent((item.progress * 100), progressCircle(item.progress.toFixed(2)))
  const editing = isEditing ? (
    <WeekItemProgressForm
      weekId={weekId}
      itemId={item.id}
      type={"item"}
      progress={item.progress}
      setEditingFalse={() => setEditing(false)}
      refetch={refetch}
      />
    ) : <div className="inline-flex">{circle}</div>
  const editingItem = isEditingItem ? (
    <WeekItemEdit
      weekId={weekId}
      itemId={item.id}
      item={item.item}
      setEditingFalse={() => setEditingItem(false)}
      refetch={refetch}
      />
    ) : <div className="inline-flex">{item.item}</div>

  return (
    <li className="inline-flex w-full">
      {editing}
      {setEditing && (
        <div className="inline-flex mx-1">
          <button
            className="inline-flex"
            onClick={() => setEditing(!isEditing)}>
              {isEditing === false && (
                <div className="inline-flex mx-1">
                  <EditIcon
                    width="25px"
                    height="25px"
                    className="inline-block"
                  />
                </div>)
              }
          </button>
          {isEditing === false && (
            <WeekItemComplete
              weekId={weekId}
              itemId={item.id}
              type={"item"}
              refetch={refetch}
            />)}
        </div>

      )}
        {' '}{editingItem}{' '}
        {setEditingItem &&
          (<button
            className="inline-flex"
            onClick={() => setEditingItem(!isEditingItem)}>
              {isEditingItem === false && (
                <EditIcon
                  width="25px"
                  height="25px"
                  className="inline-block"
                />
              )}
          </button>
        )}
        {!isEditingItem && (
          <div className="inline-flex">
            <WeekItemDelete
              weekId={weekId}
              itemId={item.id}
              type={"item"}
              refetch={refetch}
              />
          </div>
        )}
    </li>
  )
}