import React from "react";
import { Formik, Form  } from "formik";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { TODAY_TODO_PROGRESS_UPDATE } from "../../graphql/today/Mutations";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";

const TodayTodoComplete = ({
  todayId,
  todoId,
  refetch
}) => {
  const [today_todo_progress_update] = useMutation(TODAY_TODO_PROGRESS_UPDATE);
  const dispatch = useDispatch();
  return(
    <Formik
      initialValues={{
        todayId: String(todayId),
        todoId: String(todoId),
        progress: "1.0"
      }}
      onSubmit={values => {
        today_todo_progress_update({
          variables: {
            todayId: values.todayId,
            todoId: values.todoId,
            progress: values.progress
          },
        }).then((response) => {
          const { flashMessage } = response.data.todayTodoProgressUpdate;
          if (flashMessage) {
            dispatch(addFlashMessage(flashMessage));
          }
          refetch()
        });
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit} style={{display: "inline"}}>
          <button
            type="submit" >
            <CheckIcon
              width="25px"
              height="25px"
              className="inline-block"
            />
          </button>
        </Form>
      )}
    </Formik>
  )
};

export default connect()(TodayTodoComplete);