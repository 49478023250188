import gql from "graphql-tag";
import { USER_FRAGMENT, FLASH_MESSAGE_FRAGMENT } from "../gqlFragments";

export const createUser = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    createUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        authProvider: { credentials: { email: $email, password: $password } }
      }
    ) {
      token
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const signinUser = gql`
  mutation createUser($email: String!, $password: String!) {
    signinUser(input: { credentials: { email: $email, password: $password } }) {
      token
      user {
        ...UserFragment
      }
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const setPassword = gql`
  mutation setPassword($token: String!, $password: String!) {
    setPassword(input: { token: $token, password: $password }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const requestPasswordReset = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(input: { email: $email }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;
