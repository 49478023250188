import * as yup from "yup";

const emailNotLongEnough = "email must be at least 3 characters";
const nameNotLongEnough = "Name must be at least 3 characters";
const passwordNotLongEnough = "Password must be at least 8 characters";
const invalidEmail = "Email must be a valid email";

export const signUpValidator = yup.object().shape({
  email: yup
    .string()
    .min(3, emailNotLongEnough)
    .max(100)
    .email(invalidEmail)
    .required("Required"),
  firstName: yup
    .string()
    .min(3, nameNotLongEnough)
    .max(100)
    .required("Required"),
  lastName: yup
    .string()
    .min(3, nameNotLongEnough)
    .max(100)
    .required("Required"),
  password: yup
    .string()
    .min(8, passwordNotLongEnough)
    .max(100)
    .required("Required"),
});

export const loginValidator = yup.object().shape({
  email: yup
    .string()
    .min(3, emailNotLongEnough)
    .max(100)
    .email(invalidEmail)
    .required("Required"),
  password: yup
    .string()
    .min(8, passwordNotLongEnough)
    .max(100)
    .required("Required"),
});

export const formatYupError = (err) => {
  const errors = [];
  err.inner.forEach((e) => {
    errors.push({
      path: e.paht,
      message: e.message,
    });
  });
  return errors;
};
