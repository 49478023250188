import React from "react";
import { Formik, Form  } from "formik";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { TODAY_TODO_DELETE } from "../../graphql/today/Mutations";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";

const TodayTodoDelete = ({
  todayId,
  todoId,
  refetch
}) => {
  const [today_todo_delete] = useMutation(TODAY_TODO_DELETE);
  const dispatch = useDispatch();
  return(
    <Formik
      initialValues={{
        todayId: String(todayId),
        todoId: String(todoId),
      }}
      onSubmit={values => {
        today_todo_delete({
          variables: {
            todayId: values.todayId,
            todoId: values.todoId,
          },
        }).then((response) => {
          const { flashMessage } = response.data.todayTodoDelete;
          if (flashMessage) {
            dispatch(addFlashMessage(flashMessage));
          }
          refetch()
        });
      }}
    >
      {(props) => (
        <Form
          onSubmit={e =>
            window.confirm("Are you sure you wish to delete this item?") && props.handleSubmit(e)
          }
          style={{display: "inline"}}>
          <button
            type="submit" >
            <DeleteIcon
              width="25px"
              height="25px"
              className="inline-block"
            />
          </button>
        </Form>
      )}
    </Formik>
  )
};

export default connect()(TodayTodoDelete);