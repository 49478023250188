import React, { useState } from 'react'

import { EditForm } from "../Objectives";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";

export const ObjectiveRow = ({id, heading, objective, percent, weekLink, refetch}) => {
  const [isEditingObjective, setEditingObjective] = useState(false);
  const editingObjective = isEditingObjective ? (
    <EditForm
      id={id}
      objective={objective}
      setEditingFalse={() => setEditingObjective(false)}
      refetch={refetch}
    />
  ) : (
    <p className="inline-flex">{objective}</p>
  );
  return (
    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
      <dt className="font-large text-gray-700">{heading}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 inline-block">
        <div className="inline-flex">
          <span className="inline-flex mr-1">{percent}</span>
          {editingObjective}
          {setEditingObjective &&
            (<button
              className="inline-flex"
              onClick={() => setEditingObjective(!isEditingObjective)}>
              {isEditingObjective === false && (
                <EditIcon
                  width="25px"
                  height="25px"
                  className="inline-block"
                />)}
            </button>
          )}
        </div>
        <span className="block">{weekLink}</span>
      </dd>
    </div>
  )
}
