import gql from "graphql-tag";

export const FLASH_MESSAGE_FRAGMENT = gql`
  fragment FlashMessageFragment on FlashMessage {
    type
    message
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    firstName
    lastName
  }
`;

export const TODAYS_FRAGMENT = gql`
  fragment TodaysFragment on Week {
    todays {
      id
      todos
      gratefuls
      lessons
      achievements
      memories
      progress
      notes
      createdAt
      updatedAt
    }
  }
`;

export const WEEK_FRAGMENT = gql`
  fragment WeekFragment on Week {
    id
    items
    effective
    improvement
    planExecution
    progress
    objectiveId
    notes
    createdAt
    ...TodaysFragment
  }
  ${TODAYS_FRAGMENT}
`;
