import gql from "graphql-tag";
import { WEEK_FRAGMENT } from "../gqlFragments";

export const CURRENT_OBJECTIVE = gql`
  query currentObjective($id: ID!) {
    objective(id: $id) {
      id
      objective
      progress
      archive
      keyResults {
        id
        keyResult
        progress
        initiatives {
          id
          initiative
          progress
        }
      }
      weeks {
        ...WeekFragment
      }
    }
  }
  ${WEEK_FRAGMENT}
`;
