import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import combineReducers from "./redux/combineReducers";
import { LoginView, RegisterView, SetPassword, RequestPasswordReset } from "./components/User";
import { Navbar, ProtectedRoute } from "./components/Shared";
import FlashMessagesList from "./components/FlashMessages/MessagesList";
import { MeView, Form as ObjectiveForm } from "./components/Objectives/";
import {
  CompleteWeekForm,
  CreateForm as WeekCreateForm,
  CurrentWeek,
  FindWeekById,
  WeeksIndex,
} from "./components/Weeks";
import { MorningForm, EveningForm } from "./components/Today";

const middleware = applyMiddleware(thunk);

const store = createStore(
  combineReducers,
  compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f)
);

class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Provider store={store}>
          <Navbar />
          <FlashMessagesList />
          <Switch>
            <ProtectedRoute exact path="/" component={MeView} />
            <Route path="/login" component={LoginView} />
            <Route path="/signup" component={RegisterView} />
            <Route exact path="/reset_password/:resetId" component={SetPassword} />
            <Route path="/reset_password" component={RequestPasswordReset} />
            <ProtectedRoute path="/me" component={MeView} />
            <ProtectedRoute path="/new_objectives" component={ObjectiveForm} />
            <ProtectedRoute path="/weekly_plan" component={WeekCreateForm} />
            <ProtectedRoute exact path="/weeks/:objectiveId" component={WeeksIndex} />
            <ProtectedRoute exact path="/current_week/" component={CurrentWeek} />
            <ProtectedRoute exact path="/week/:weekId" component={FindWeekById} />
            <ProtectedRoute exact path="/week/:weekId/complete/:objectiveId" component={CompleteWeekForm} />
            <ProtectedRoute exact path="/today/:weekId/morning" component={MorningForm} />
            <ProtectedRoute exact path="/today/:weekId/evening/:todayId" component={EveningForm} />
          </Switch>
        </Provider>
      </BrowserRouter>
    );
  }
}

export default Routes;
