import React, { useState } from 'react'

import { EditForm } from "../KeyResults";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";


export const KeyResultRow = ({id, heading, keyResult, percent, refetch}) => {
    const [isEditingKeyResult, setEditingKeyResult] = useState(false);
    const editingKeyResult = isEditingKeyResult ? (
    <EditForm
      id={id}
      keyResult={keyResult}
      setEditingFalse={() => setEditingKeyResult(false)}
      refetch={refetch}
    />
  ) : (
    <span className="inline-flex">{keyResult}</span>
  );
  return (
    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
      <dt className="font-large text-gray-700">{heading}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 inline-flex">
        <p className="inline-block mr-1">{percent}</p>
        {editingKeyResult}
        {setEditingKeyResult &&
          (<button
            className="inline-flex"
            onClick={() => setEditingKeyResult(!isEditingKeyResult)}>
            {isEditingKeyResult === false && (
              <EditIcon
                width="25px"
                height="25px"
                className="inline-block"
              />)}
          </button>
        )}
      </dd>
    </div>
  )
}
