import React, { useState } from 'react'

import NotesAdd from "./NotesAdd"
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";

export const NotesSection = ({weekId, notes, refetch}) => {
  const [isEditingNotes, setEditingNotes] = useState(false);
  const [isAddingNotes, setAddingNotes] = useState(false);
  const editingNotes = isEditingNotes ? (
  <NotesAdd
    weekId={weekId}
    notes={notes}
    noteEdit={true}
    setEditingFalse={() => setEditingNotes(false)}
    refetch={refetch}
    editing="true"
    />
  ) : <p className="inline-flex whitespace-pre-wrap">{notes}</p>;

  const noteAdding = isAddingNotes ? (
      <NotesAdd
        weekId={weekId}
        setEditingFalse={() => setAddingNotes(false)}
        refetch={refetch}
        />
    ) : (
      <button
        className="block text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => setAddingNotes(!isAddingNotes)}
        >
        Add Note
      </button>
    );

  return (
    <div>
      {noteAdding}
      <div>
        {notes && setEditingNotes &&
          (<button
            onClick={() => setEditingNotes(!isEditingNotes)}>
            {isEditingNotes === false && (
              <EditIcon
                width="25px"
                height="25px"
                className="inline-block"
              />
            )}
          </button>
        )}
        {editingNotes}
      </div>
    </div>
  )
}
