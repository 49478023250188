import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import classNames from "classnames";

import { setAuth } from "../../redux/actions/auth";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Objective } from "../../assets/icons/new_objective.svg";
import { ReactComponent as Week } from "../../assets/icons/week.svg";
import { ReactComponent as CurrentWeek } from "../../assets/icons/current_week.svg";

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      currentStep: JSON.parse(localStorage.getItem("objectiveStep")) || 1,
      hasObjective: JSON.parse(localStorage.getItem("objectiveId")) || null,
      hasCurrentWeek: JSON.parse(localStorage.getItem("currentWeek")) || null
    };
  }

  logout = () => {
    this.props.dispatch(setAuth(false, null));
  };

  clearCurrentObjective = () => {
    localStorage.setItem("objectiveStep", 1);
  }

  render() {
    const { menuOpen } = this.state;
    const { auth } = this.props;
    const classes = classNames(
      "w-full",
      "block",
      "flex-grow",
      "lg:flex",
      "lg:items-center",
      "lg:w-auto",
      {
        flex: menuOpen,
        hidden: !menuOpen,
      }
    );
    const signInPath = this.props.location.pathname === "/login";
    const homeURL = signInPath ? "http://www.lidger.com" : "/";
    const newObjectiveURL = "/new_objectives";
    const newWeekPlan = "/weekly_plan";
    const currentWeek = "/current_week";
    return (
      <nav className="flex items-center justify-between flex-wrap bg-blue-600 p-2">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <Logo height={50} width={50} viewBox="0 0 75 75" />
          <a
            href={homeURL}
            className="font-semibold text-xl tracking-tight ml-4"
          >
            Lidger
          </a>
        </div>
        <div className="block lg:hidden">
          <button
            className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-white hover:bg-blue-700 hover:border-transparent outline-none"
            onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div className={classes}>
          <div className="text-sm lg:flex-grow">
            <a
              href={homeURL}
              className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-blue-200 mr-4"
            >
              <Dashboard
                width="25px"
                height="25px"
                className="inline-block mx-2"
              />
              Dashboard
            </a>
            <a
              href={newObjectiveURL}
              className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-blue-200 mr-4"
              onClick={() => this.clearCurrentObjective()}
            >
              <Objective
                width="25px"
                height="25px"
                className="inline-block mx-2"
              />
              Start A Objective
            </a>
            {this.state.hasObjective && (
              <a
                href={newWeekPlan}
                className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-blue-200 mr-4"
              >
                <Week
                  width="25px"
                  height="25px"
                  className="inline-block mx-2"
                />
                Plan Your Week
              </a>
            )}
            {this.state.hasCurrentWeek && (
              <a
              href={currentWeek}
              className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-blue-200 mr-4"
            >
              <CurrentWeek
                width="25px"
                height="25px"
                className="inline-block mx-2"
              />
              Current Week
            </a>
            )}
            {this.state.currentStep !== 1 && (<a
              href={newObjectiveURL}
              className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-blue-200 mr-4"
            >
              <Objective
                width="25px"
                height="25px"
                className="inline-block mx-2"
              />
              Continue Building Your Objective
            </a>)}

          </div>
          <div>
            {auth ? (
              <button
                className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:text-white hover: hover:bg-blue-700 mt-4 lg:mt-0"
                onClick={() => this.logout()}
              >
                Logout
              </button>
            ) : signInPath ? (
              <a
                href="/signup"
                className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:text-white hover:bg-blue-700 mt-4 lg:mt-0"
              >
                Sign Up
              </a>
            ) : (
              <a
                href="/login"
                className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:text-white hover:bg-blue-700 mt-4 lg:mt-0"
              >
                Login
              </a>
            )}
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.isAuth,
  };
};

export default withRouter(connect(mapStateToProps)(Navbar));
