import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { OBJECTIVE_WEEKS } from "../../graphql/weeks/Queries";
import { WeekTodosView } from "./WeekTodosView"

function WeeksIndex() {
  let { objectiveId } = useParams();
  const { loading, error, data, refetch} = useQuery(OBJECTIVE_WEEKS, {
    variables: {id: objectiveId},
    fetchPolicy: "no-cache"
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;
  const { objectiveWeeks } = data

  return (
    <div className="grid grid-cols-12 bg-gray-400 min-h-screen h-auto">
      <div className="sm:col-start-2 col-span-12 sm:col-span-10">
        <div className="grid grid-cols-8 m-2">
          <div className="rounded m-2 col-span-8">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="bg-white rounded px-8 pt-6 pb-8 mb-4">
                {objectiveWeeks ? (
                  objectiveWeeks.map(w =>
                    <WeekTodosView key={w.id} {...w} refetch={refetch} />
                  )) : "No weeks create"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default WeeksIndex;
