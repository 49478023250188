import React from "react";
import { Formik, Form, Field  } from "formik";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { WEEK_ITEM_ADD } from "../../graphql/weeks/Mutations";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";

const validationSchema = Yup.object().shape({
  item: Yup
    .string()
    .required("Required"),
});

const WeekItemAdd = ({
  weekId,
  itemId,
  setEditingFalse,
  refetch
}) => {
  const [week_item_add] = useMutation(WEEK_ITEM_ADD);
  const dispatch = useDispatch();
  return(
    <Formik
      initialValues={{
        weekId: String(weekId),
        itemId: String(itemId),
        item: ''
      }}
      validationSchema={validationSchema}
      onSubmit={values => {
        week_item_add({
          variables: {
            weekId: values.weekId,
            itemId: values.itemId,
            item: values.item
          },
        }).then((response) => {
          const { flashMessage } = response.data.weekItemAdd;
          if (flashMessage) {
            dispatch(addFlashMessage(flashMessage));
          }
          setEditingFalse()
          refetch()
        });
      }}
    >
      {({ handleSubmit, errors}) => (
        <Form onSubmit={handleSubmit} style={{display: "inline"}}>
          <Field
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-1/2 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            name="item"
            type="text"
            placeholder="Add to your items"
            />
          <button
            type="submit" >
            <PlusIcon
              width="25px"
              height="25px"
              className="inline-block"
            />
          </button>
          <button
            onClick={() => setEditingFalse()} >
            <DeleteIcon
              width="25px"
              height="25px"
              className="inline-block"
            />
          </button>
          {errors.item && (
            <p className="text-red-500 text-xs italic">
              {errors.item}
            </p>
          )}
        </Form>
      )}
    </Formik>
  )
};

export default connect()(WeekItemAdd);