import * as React from "react";

const ForgotPasswordLink = () => {
  return (
    <a
      className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 m-3"
      href="/reset_password"
    >
      Forgot Password?
    </a>
  );
};

export default ForgotPasswordLink;
