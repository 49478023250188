import React from "react";
import { isEmpty } from "lodash";
import { Link, useLocation } from "react-router-dom";

import { ObjectiveRow } from "../Shared/ObjectiveRow";
import { KeyResultRow } from "../Shared/KeyResultRow";
import { InitiativeRow } from "../Shared/InitiativeRow";
import { circleWithPercent } from "../Shared/CircleWithPercent";
import { progressColor, progressCircle } from "../Shared/ProgressColor";
import Archive from "./Archive";

export const Objective = ({ objective, length, refetch }) => {
  const location = useLocation();
  const weekLink = isEmpty(objective.weeks) ? "weekly_plan" : `week/${objective.weeks[0].id}`;
  const { progress } = objective;
  const { pathname } = location;
  const classNameProgress = `overflow-hidden h-2 mb-4 text-xs flex rounded ` + progressColor(progress);
  const circle = circleWithPercent(progress * 100, progressCircle(progress.toFixed(2)));
  const objLength = length > 1 ? "m-2 col-span-8 lg:col-span-4" : "m-2 col-span-8";
  const objectiveWeeks = `/weeks/${objective.id}`;

  return (
    <div className={objLength}>
      <div className="rounded m-2 col-span-8">
        <div className="bg-white rounded overflow-hidden sm:rounded-lg">
          <div className="bg-white rounded px-8 pt-6 pb-8 mb-4">
            <ObjectiveRow
              id={objective.id}
              heading={"Objective"}
              objective={objective.objective}
              percent={circle}
              refetch={refetch}
              weekLink={
                pathname === "/weekly_plan" ? null : isEmpty(objective.weeks) ? (
                  <div>
                    <Link
                      className="text-xs bg-green-500 hover:bg-green-700 text-white font-bold py-2 my-2 px-4 rounded animate-bounce font-bold inline-block"
                      to={weekLink}
                    >
                      Plan Your Week
                    </Link>
                    <Archive
                      classname="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 my-2 ml-4 px-4 rounded inline-block"
                      id={objective.id}
                      archive={objective.archive}
                      refetch={refetch}
                    />
                  </div>
                ) : (
                  <div>
                    <Link
                      className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 my-2 px-4 rounded inline-block"
                      to={weekLink}
                    >
                      View Current Week
                    </Link>
                    <Link
                      className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 my-2 ml-4 px-4 rounded inline-block"
                      to={objectiveWeeks}
                    >
                      View All Weeks
                    </Link>
                    <Archive
                      classname="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 my-2 ml-4 px-4 rounded inline-block"
                      id={objective.id}
                      archive={objective.archive}
                      refetch={refetch}
                    />
                  </div>
                )
              }
            />
            <div>
              <div className="relative pt-1">
                <div className={classNameProgress}></div>
              </div>
              {objective.keyResults &&
                objective.keyResults.length > 0 &&
                objective.keyResults.map((kr) => <KeyResult key={kr.id} kr={kr} refetch={refetch} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const KeyResult = ({ kr, refetch }) => {
  const classNameProgress = `overflow-hidden h-1 mb-4 text-xs flex rounded ` + progressColor(kr.progress);
  const circle = circleWithPercent(kr.progress * 100, progressCircle(kr.progress.toFixed(2)));

  return (
    <div key={kr.id}>
      <KeyResultRow id={kr.id} heading={"Key Result"} keyResult={kr.keyResult} percent={circle} refetch={refetch} />
      <div>
        {kr.initiatives.length > 0 && (
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-600">Initiatives</dt>
            <dd className="mt-1 text-sm text-gray-600 sm:mt-0 sm:col-span-3 inline-flex flex-col">
              <div className="relative pt-1">
                <div className={classNameProgress}></div>
              </div>
              {kr.initiatives.map((i) => (
                <Initiative key={i.id} i={i} refetch={refetch} />
              ))}
              <div className="relative pt-1">
                <div className={classNameProgress}></div>
              </div>
            </dd>
          </div>
        )}
      </div>
    </div>
  );
};

const Initiative = ({ i, refetch }) => {
  return (
    <div key={i.id}>
      <InitiativeRow initiative={i} refetch={refetch} />
    </div>
  );
};
