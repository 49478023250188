import React from "react";
import { Formik, Form, Field  } from "formik";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { TODAY_TODO_UPDATE } from "../../graphql/today/Mutations";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";

const validationSchema = Yup.object().shape({
  todo: Yup
    .string()
    .required("Required"),
});

const TodoAdd = ({
  todayId,
  todoId,
  todo,
  setEditingFalse,
  refetch
}) => {
  const [todayTodoUpdate] = useMutation(TODAY_TODO_UPDATE);
  const dispatch = useDispatch();
  return(
    <Formik
      initialValues={{
        todayId: String(todayId),
        todoId: String(todoId),
        todo: todo || ''
      }}
      validationSchema={validationSchema}
      onSubmit={values => {
        todayTodoUpdate({
          variables: {
            todayId: values.todayId,
            todoId: values.todoId,
            todo: values.todo
          },
        }).then((response) => {
          const { flashMessage } = response.data.todayTodoUpdate;
          if (flashMessage) {
            dispatch(addFlashMessage(flashMessage));
          }
          setEditingFalse()
          refetch()
        });
      }}
    >
      {({handleSubmit, errors}) => (
        <Form onSubmit={handleSubmit} className="flex-grow">
          <Field
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-2/3 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            name="todo"
            type="text"
            placeholder="Add to your To-Dos "
            />
          <button
            type="submit" >
            <CheckIcon
              width="25px"
              height="25px"
              className="inline-block"
            />
          </button>
          <button
            onClick={() => setEditingFalse()} >
            <DeleteIcon
              width="25px"
              height="25px"
              className="inline-block"
            />
          </button>
          {errors.todo && (
            <p className="text-red-500 text-xs italic">
              {errors.todo}
            </p>
          )}
        </Form>
      )}
    </Formik>
  )
};

export default connect()(TodoAdd);