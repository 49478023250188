import React, { useState } from 'react'
import { ListItem } from "./ListItem";
import WeekItemAdd from "./WeekItemAdd"

export const ItemsSection = ({weekId, items, refetch}) => {
  const [isItemAdding, setItemAdding] = useState(false);
  const itemAdding = isItemAdding ? (
    <WeekItemAdd
      weekId={weekId}
      type={"item"}
      setEditingFalse={() => setItemAdding(false)}
      refetch={refetch}
      />
  ) : (
    <button
      className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 my-2 px-4 rounded"
      onClick={() => setItemAdding(!isItemAdding)}
      >
      Add Item (8 Max)
    </button>
  );

  return (
    <ul>
      { items && items.map(i => <ListItem key={i.id} item={i} weekId={weekId} refetch={refetch} /> )}
      { items.length < 8 && itemAdding }
    </ul>
  )
}
