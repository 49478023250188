import gql from "graphql-tag";
import { WEEK_FRAGMENT } from "../gqlFragments";

export const CURRENT_WEEK = gql`
  query currentWeek {
    currentWeek {
      ...WeekFragment
    }
  }
  ${WEEK_FRAGMENT}
`;

export const GET_WEEK = gql`
  query week($id: ID!) {
    week(id: $id) {
      ...WeekFragment
    }
  }
  ${WEEK_FRAGMENT}
`;

export const OBJECTIVE_WEEKS = gql`
  query objectiveWeeks($id: ID!) {
    objectiveWeeks(id: $id) {
      id
      items
      progress
      notes
      createdAt
    }
  }
`;
