const colorHelper = (rounded) => ({
0: "red-600",
10: "red-500",
20: "red-400",
30: "pink-400",
40: "pink-300",
50: "yellow-400",
60: "yellow-500",
70: "green-300",
80: "green-400",
90: "green-500",
100: "green-700",
})[rounded]

export const progressColor = (progress) => {
  //return value to closest 10
  const rounded = Math.ceil((progress * 100) / 10) * 10
  return `bg-${colorHelper(rounded)}`
}

export const progressCircle = (progress) => {
  //return value to closest 10
  const rounded = Math.ceil((progress * 100) / 10) * 10
  return `text-${colorHelper(rounded)}`
}