import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { toString } from "lodash";
import { CURRENT_OBJECTIVE } from "../../graphql/objectives/Queries";

import ObjectiveForm from "./ObjectiveForm";
import { KeyResultForm } from "../KeyResults";
import { InitiativeForm } from "../Initiatives";

export const Form = () => {
  const [currentStep, setCurrentStep] = useState(
    JSON.parse(localStorage.getItem("objectiveStep")) || 1
  );
  const [objectiveId, setObjectiveId] = useState(
    JSON.parse(localStorage.getItem("objectiveId")) || 1
  );

  const { data, refetch } = useQuery(CURRENT_OBJECTIVE, {
    variables: { id: toString(objectiveId) },
  });

  const localStoreStep = (step, id) => {
    localStorage.setItem("objectiveStep", step);
    if (id){
      localStorage.setItem("objectiveId", id)
    }
    setObjectiveId(id);
    setCurrentStep(step);
  };

  const view = (currentStep) => {
    switch (currentStep) {
      case 1:
        return (
          <ObjectiveForm
            action="new"
            nextStep={(id) => localStoreStep(currentStep + 1, id)}
          />
        );
      case 2:
        return <KeyResultForm
          action="new"
          objectiveId={objectiveId}
          objective={data && data.objective}
          refetch={() => refetch()}
          nextStep={(id) => localStoreStep(currentStep + 1, id)}
        />;
      case 3:
        return <InitiativeForm
          action="new"
          keyResults={data && data.objective.keyResults}
          refetch={() => refetch()}
        />;
      default:
        break;
    }
  };

  return (
    <div className="grid grid-cols-12 bg-gray-400 min-h-screen h-auto">
      <div className="sm:col-start-2 col-span-12 sm:col-span-10">
        <div className="grid grid-cols-8 m-2">
          <div className="bg-white rounded m-2 col-span-8">
            <h1 className="mb-4 text-center">Your Next Objective</h1>
            {view(currentStep)}
          </div>
        </div>
      </div>
    </div>
  );
};
