import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { SubmitButton } from "../Shared/styles";
import { CREATE_KEY_RESULTS } from "../../graphql/key_results/Mutations";

import { HeadingRow } from "../Shared/HeadingRow"
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";

const validationSchema = Yup.object().shape({
  keyResults: Yup.array().of(
    Yup.object().shape({
      key_result: Yup.string().required("Required"),
    })
  )
});

const getKeyResultErrors = (errors, i) => {
  const { [i]: err } = errors
  return err ? err["key_result"] : null
};

const KeyResultForm = ({
  id,
  objective,
  refetch,
  objectiveId,
  nextStep
}) => {
  const [createKeyResults] = useMutation(CREATE_KEY_RESULTS);
  const dispatch = useDispatch();
  const currentObjective = objective ? <b>{objective.objective}</b> : null

  return(
    <div className="bg-white rounded px-8 pt-6 pb-8 mb-4">
      <h3 className="mb-4 text-center">Step 2</h3>
      <h4 className="mb-4 border-t-4 border-gray-200 justify-self-auto text-center lg:mx-40">Now let's break down your {objective && currentObjective} objective into multiple Key Results, Key Results should be Specific, Measureable, Quantitative. We recomend between 3 to 5 Key Results.</h4>
      <Formik
        initialValues={{
          keyResults:[
            {
              id: id || '',
              key_result: '',
              objective_id: objectiveId
            },
            {
              id: id || '',
              key_result: '',
              objective_id: objectiveId
            },
            {
              id: id || '',
              key_result: '',
              objective_id: objectiveId
            }
          ]
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => {
          createKeyResults({
            variables: {
              keyResults: values.keyResults
            },
          }).then((response) => {
            const { flashMessage } = response.data.createKeyResults;
            if (flashMessage) {
              dispatch(addFlashMessage(flashMessage));
            }
            nextStep(objectiveId)
            refetch();
          });
        }}
      >
      {({ values,
          errors }) => (
        <Form>
          <div className="lg:mx-48">
            <HeadingRow
              heading={"Key Results:"}
              content={
              <FieldArray name="keyResults">
                {(arrayHelpers) => (
                  <div>
                    {values.keyResults.length > 0 &&
                      values.keyResults.map((keyResult, index) => (
                        <div key={index}>
                          <div className="sm:col-start-2 col-span-12 sm:col-span-10">
                            <Field
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-5/6 my-2 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                              name={`keyResults.${index}.key_result`}
                              type="text"
                              placeholder="Key Result"
                            />
                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}>
                              <DeleteIcon
                                width="25px"
                                height="25px"
                                className="inline-block"
                              />
                            </button>
                            {errors.keyResults && (
                              <p className="text-red-500 text-xs italic">
                               {getKeyResultErrors(errors.keyResults, index) && getKeyResultErrors(errors.keyResults, index)}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                      {values.keyResults.length <= 4 &&
                        <button
                          type="button"
                          className="text-xs bg-green-500 hover:bg-green-700 text-white font-bold py-2 my-2 px-4 rounded"
                          onClick={() => arrayHelpers.push({ id: id || '', key_result: '', objective_id: objectiveId})}
                        >
                          Add another Key Results
                        </button>}
                  </div>
                )}
              </FieldArray>}
            />
          </div>
          <div className="text-center my-8"><SubmitButton type="submit">Next Step</SubmitButton></div>

        </Form>
      )}
      </Formik>
    </div>
  )

};

export default connect()(KeyResultForm);