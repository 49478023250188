import { SET_AUTH } from "./types";

export const setAuth = (boolean, token) => {
  if (token == null) {
    localStorage.removeItem("token");
  } else {
    localStorage.setItem("token", JSON.stringify(token));
  }

  return {
    type: SET_AUTH,
    boolean,
  };
};
