import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { UPDATE_OBJECTIVE } from "../../graphql/objectives/Mutations";

import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/caution.svg";

const validationSchema = yup.object().shape({
  objective: yup.string().required("Required")
});

const EditForm = ({ id, objective, setEditingFalse, refetch}) => {
  const [updateObjective] = useMutation(UPDATE_OBJECTIVE);
  const dispatch = useDispatch();
  const { handleSubmit, errors, handleChange, values } = useFormik({
    initialValues: {
      id: id,
      objective: objective || '',
    },
    validationSchema,
    onSubmit: (values) => {
      updateObjective({
        variables: {
          id: values.id,
          objective: values.objective,
          action: 'update'
        },
      }).then((response) => {
        const { objectiveReturn } = response.data.updateObjective;
        const { flashMessage } = objectiveReturn;
        if (flashMessage) {
          dispatch(addFlashMessage(flashMessage));
        }
        setEditingFalse();
        refetch();
      });
    },
  });

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded inline-block">
          <div>
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-3/4 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              id="objective"
              name="objective"
              type="text"
              onChange={handleChange}
              value={values.objective}
              initialvalue=""
              placeholder="Edit objective"
            />
            <button
              type="submit" >
              <CheckIcon
                width="25px"
                height="25px"
                className="inline-block"
              />
            </button>
            <button
              onClick={() => setEditingFalse()} >
              <CautionIcon
                width="25px"
                height="25px"
                className="inline-block"
              />
            </button>
          </div>
          <p className="text-red-500 text-xs italic">{errors.objective && errors.objective}</p>
      </form>
    </div>
  );
};

export default connect()(EditForm);
