import React from "react";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";

import { addFlashMessage } from "../../redux/actions/flashMessages";
import { OBJECTIVE_ARCHIVE } from "../../graphql/objectives/Mutations";
import { ReactComponent as ArchiveIcon } from "../../assets/icons/archive.svg";

const Archive = ({
  id,
  refetch,
  classname,
  archive
}) => {
  const [objective_archive] = useMutation(OBJECTIVE_ARCHIVE);
  const dispatch = useDispatch();
  const archiveState = archive ? "Unarchive" : "Archive"
  return (
      <Formik
        initialValues={{
          objective_id: id || "",
        }}
        onSubmit={values => {
          objective_archive({
            variables: {
              objective_id: values.objective_id,
            },
          }).then((response) => {
            const { flashMessage } = response.data.objectiveArchive;
            if (flashMessage) {
              dispatch(addFlashMessage(flashMessage));
            }
            refetch()
          });
        }}

      >
        {(props) => (
            <Form
              onSubmit={e =>
                window.confirm("Are you sure you wish to archive this Objective?") && props.handleSubmit(e)
              }
              className="inline-block"
            >
              <button
                type="submit"
                className={classname}
              >
                <ArchiveIcon
                  width="20px"
                  height="20px"
                  className="inline-block mr-1"
                />
                {archiveState}
              </button>
            </Form>
        )}
      </Formik>
  )
};

export default connect()(Archive);