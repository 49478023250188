import gql from "graphql-tag";
import { FLASH_MESSAGE_FRAGMENT } from "../gqlFragments";

export const CREATE_KEY_RESULTS = gql`
  mutation createKeyResults($keyResults: [JSON!]) {
    createKeyResults(input: { keyResults: $keyResults }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;

export const UPDATE_KEY_RESULT = gql`
  mutation updateKeyResult($id: String, $keyResult: String!) {
    updateKeyResult(input: { id: $id, keyResult: $keyResult }) {
      flashMessage {
        ...FlashMessageFragment
      }
    }
  }
  ${FLASH_MESSAGE_FRAGMENT}
`;
